import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import Header from "../components/Header";
import { AuthContext } from "../context/Auth";
import useWidgets from "../hooks/useWidgets";
import { ConsultationContext } from "../context/Consultation";
import Footer from "../components/Footer";
import { AnalyticsContext } from "../context/Analytics";

import nasscom from "../staticAssets/nasscom.png";
import webengage from "../staticAssets/webengage.jpeg";
import villageglobal from "../staticAssets/villageglobal.png";
import iseed from "../staticAssets/iseed.jpeg";
import { navigate } from "gatsby";
import Sec1 from "../components/homePage/Sec1";
import Sec2 from "../components/homePage/Sec2";
import Sec3 from "../components/homePage/Sec3";
import Sec4 from "../components/homePage/Sec4";
import Sec5 from "../components/homePage/Sec5";
import Sec6 from "../components/homePage/Sec6";

import gp from "../../src/staticAssets/gp.png";
import ap from "../../src/staticAssets/image 116.png";

const limit = 20;

const IndexPage = () => {
  const [page, setpage] = useState(1);
  const [host, sethost] = useState("");
  const [category, setcategory] = useState("all");
  const [showDownloadBar, setShowDownloadBar] = useState(true);

  const {
    isLoading,
    widgets,
    hasMore,
    setStatus,
  } = useWidgets(category, page, limit, setpage);
  const { user, fetchUser } = useContext(AuthContext) || {
    user: {},
    fetchUser: () => {},
  };
  const { astrologerId } = useContext(ConsultationContext) || {
    astrologerId: "",
  };

  const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };

  useEffect(() => {
    gtmPageViewEvent(window.location.pathname, window.location.host);
  }, []);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setpage((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );
  useEffect(() => {
    sethost(window.location.host);
    fetchUser();
  }, []);

  return (
    <div className="h-screen flex flex-col hide-scroll-bar scrollbar-hide">
      <Header />
      <div className="flex-1 overflow-y-auto flex flex-col z-0">
        <main className="grow  hide-scroll-bar scrollbar-hide">
            <Sec1 user={user} />
            <Sec2
              widgets={widgets}
              setStatus={setStatus}
              astrologerId={astrologerId}
            />
            <Sec3 />
            <Sec4 />
            <Sec5 user={user} />
            <Sec6 />
          <div className="mt-20">
            <h4 className="text-black text-[25px] md:text-[50px] font-bold playfair text-center">
              Our Supporters
            </h4>
            <div className="flex flex-wrap items-center container mx-auto justify-evenly my-10">
              <img
                loading="lazy"
                className="object-contain h-8 my-4"
                width="300"
                height="100"
                src={nasscom}
                alt="Nasscom"
              />
              <img
                loading="lazy"
                className="object-contain h-24 mt-4"
                width="300"
                height="100"
                src={villageglobal}
                alt="Village Global"
              />
              <img
                loading="lazy"
                className="object-contain h-[3rem] md:h-[3.5rem]"
                src={iseed}
                width="300"
                height="100"
                alt="ISeed"
              />
              <img
                loading="lazy"
                className="object-contain h-12 mt-4 md:mt-0 mb-4"
                width="300"
                height="100"
                src={webengage}
                alt="Webengage"
              />
            </div>
          </div>
          <button
            className={`justify-center text-white fixed right-2 ${showDownloadBar ? "bottom-16" : "bottom-2"} lg:bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl`}
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined
              ? `Consult Top Astrologers Now!`
              : `Login & get ${
                  user?.currencyType == "usd" ? "$ 4" : "₹ 91"
                } in your account!`}
          </button>
          {showDownloadBar && <div className="lg:hidden fixed right-0 bottom-0 w-screen bg-black h-14 bg-opacity-95 flex items-center justify-evenly">
            <a 
              href="https://bit.ly/BodhiWeb"
              aria-label="Google Play Store Link"
            >
              <img
                loading="lazy"
                src={gp}
                width="200"
                height="100"
                className="h-[60px] object-contain py-2"
                alt="Google Play Store Image"
              />
            </a>
            <a 
              href="https://bit.ly/BodhiWeb"
              aria-label="App Store Link"
            >
              <img
                loading="lazy"
                src={ap}
                width="160"
                height="100"
                className="h-[60px] object-contain py-2"
                alt="App Store Image"
              />
            </a>
            <button 
              className="text-white pr-2"
              onClick={()=>{
                setShowDownloadBar(false);
              }}
            >
              X
            </button>
          </div>}
        </main>

        {host != "" &&
          (host != "astroguru.bodhiness.com" ||
            host != "mobafl.bodhiness.com" ||
            host != "webj.bodhiness.com" ||
            host != "lokal.bodhiness.com" ||
            host != "namah.bodhiness.com") && <Footer />}
      </div>
    </div>
  );
};

export default IndexPage;
